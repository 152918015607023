<template>
  <div id="analytics">
    <b-row
      class="mt-4"
      style="row-gap: 1.5rem"
    >
      <b-col
        xl="6"
        md="3"
      >
        <div class="analytics__item">
          <div class="analytics__item-title">Выручка, ₽</div>
          <div class="analytics__item-sum">
            {{ formatPrice(analytics.revenue) }}
          </div>
        </div>
      </b-col>
      <b-col
        xl="6"
        md="3"
      >
        <div class="analytics__item">
          <div class="analytics__item-title">Возвраты, ₽</div>
          <div class="analytics__item-sum">
            {{ formatPrice(analytics.returns) }}
          </div>
        </div>
      </b-col>
      <b-col
        xl="6"
        md="3"
      >
        <div class="analytics__item">
          <div class="analytics__item-title">Всего чеков, шт</div>
          <div class="analytics__item-sum">
            {{ analytics.quantity }}
          </div>
        </div>
      </b-col>
      <b-col
        xl="6"
        md="3"
      >
        <div class="analytics__item">
          <div class="analytics__item-title">Средний чек, ₽</div>
          <div class="analytics__item-sum">
            {{ formatPrice(analytics.average) }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    props: {
      period: {
        type: [Object, String, Array],
        default: () => {}
      },
      time: {
        type: Object
      }
    },

    apollo: {
      CheckAnalytics: {
        query: require('../../dashboard/gql/getAnalytics.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch?.__typename === 'BranchClassifModel' ? '' : this.currentBranch.id,
            period: {
              begin: new Date(new Date(this.period[0]).setHours(this.beginTime.hour, this.beginTime.minute, 0)),
              end: new Date(new Date(this.period[1]).setHours(this.endTime.hour, this.endTime.minute, 59))
            },
            branches:
              this.getCurrentBranchClassifList?.length > 0 ? this.getCurrentBranchClassifList?.map((el) => el.id) : null
          }
        },
        result({ data }) {
          this.analytics.average = R.pathOr(0, ['CheckAnalytics', 'average'], data)
          this.analytics.quantity = R.pathOr(0, ['CheckAnalytics', 'quantity'], data)
          this.analytics.returns = R.pathOr(0, ['CheckAnalytics', 'returns'], data)
          this.analytics.revenue = R.pathOr(0, ['CheckAnalytics', 'revenue'], data)
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getCurrentBranchClassifList: 'settings/getCurrentBranchClassifList'
      }),
      beginTime() {
        const arr = this.time.time_b.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      },
      endTime() {
        const arr = this.time.time_e.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      }
    },

    data: function () {
      return {
        analytics: {
          returns: 0,
          average: 0,
          quantity: 0,
          revenue: 0
        }
      }
    },

    methods: {
      formatPrice(val) {
        return val.toLocaleString('ru-RU', {
          style: 'currency',
          currency: 'RUB'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  #analytics {
    .analytics__item {
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #313131;

      &-title {
        font-size: 14px;
        line-height: 16px;
        color: #888888;
        margin-bottom: 8px;
      }
    }
  }
</style>
