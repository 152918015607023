<template>
  <div class="footer">
    <div
      v-if="total"
      class="footer-item"
    >
      Итого: 6666
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TableFooter',
    props: {
      total: {
        type: Number,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    display: flex;
  }
</style>
