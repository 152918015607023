<template>
  <section id="cash-sales-report">
    <div class="b-filter-date">
      <date-picker
        v-model="data_range"
        type="datetimerange"
        format="dd.MM.yyyy"
        :clearable="false"
        class="elm-calendar date"
        placeholder="Выберите период"
        @on-change="updateDateRange"
      />
      <time-picker
        v-model="time.time_b"
        theme="white"
        :value="time.time_b"
        locale="ru"
        class="time"
        is24hr
        :clearable="false"
        format="HH:mm"
      />
      <b>-</b>
      <time-picker
        v-model="time.time_e"
        theme="white"
        :value="time.time_e"
        locale="ru"
        is24hr
        :clearable="false"
        format="HH:mm"
        class="time"
      />

      <div
        v-if="getCurrentBranch?.branches"
        class="select-block"
      >
        <e-select
          :value="currentBranch"
          class="e-select-el"
          :options="getCurrentBranch?.branches"
          multiple
          :max_selected_count="1"
          :background_color="'#e0edff'"
          :color_text="'#00A3FF'"
          :color_close_svg="'#00A3FF'"
          :placeholder="getCurrentBranch?.name"
          select_name="branch_сlassif"
          @input="setBranches"
        >
          <template #custom_name="{ item }">
            <div>
              <div class="e-select-el__option-name">{{ item.name }}</div>
            </div>
          </template>
        </e-select>
      </div>
    </div>
    <b-row
      class="widget-height mt-3"
      style="row-gap: 1.5rem"
    >
      <b-col
        xl="4"
        md="12"
      >
        <b-card class="p-0 mb-0 h-100">
          <div class="widget-title">Всего за период</div>
          <total-period
            :period="data_range"
            :time="time"
          />
        </b-card>
      </b-col>
      <b-col
        xl="8"
        md="12"
      >
        <b-card class="p-0 m-0 h-100">
          <data-by-hours
            :period="data_range"
            :time="time"
          />
        </b-card>
      </b-col>
    </b-row>
    <div class="block-cash-type">
      <div class="cash-type">
        <b-card class="p-0">
          <div class="widget-title">Тип оплаты по продажам</div>
          <payment-type
            :period="data_range"
            :time="time"
          />
        </b-card>
      </div>
      <div class="cash-type">
        <b-card class="p-0">
          <div class="widget-title">Тип оплаты по возвратам</div>
          <return-type
            :period="data_range"
            :time="time"
          />
        </b-card>
      </div>
    </div>

    <cash-tabs
      :data_range="data_range"
      :z_numbers_list="z_numbers_list"
      :time="time"
    />
  </section>
</template>

<script>
  import TotalPeriod from '../charts/TotalPeriod.vue'
  import DataByHours from '../charts/DataByHours.vue'
  import PaymentType from '../charts/PaymentType.vue'
  import formatDate from '@/utils/formatDate'
  import CashTabs from '@/views/analytics/components/CashSalesTabs/CashTabs'
  import ReturnType from '@/views/analytics/charts/ReturnType'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    components: { ReturnType, CashTabs, TotalPeriod, DataByHours, PaymentType },

    apollo: {
      ZNumberFilters: {
        query: require('../gql/ZNumberFilters.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.getCurrentBranch.id?.__typename === 'BranchClassifModel' ? '' : this.getCurrentBranch.id,
              period: {
                begin: new Date(new Date(this.data_range[0]).setHours(this.beginTime.hour, this.beginTime.minute, 0)),
                end: new Date(new Date(this.data_range[1]).setHours(this.endTime.hour, this.endTime.minute, 59))
              },
              pagination: {
                skip: 0,
                take: 20
              }
            },
            branches:
              this.getCurrentBranchClassifList?.length > 0 ? this.getCurrentBranchClassifList?.map((el) => el.id) : null
          }
        },
        result({ data }) {
          this.z_numbers_list = data?.ZNumberFilters?.list ?? []
        },
        skip() {
          return !this.data_range[0]
        }
      }
    },

    data() {
      return {
        data_range: [],
        currentBranch: [],
        branch_сlassif: [],
        time: {
          time_b: '00:00',
          time_e: '23:59'
        },
        z_numbers_list: []
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch',
        getCurrentBranchClassifList: 'settings/getCurrentBranchClassifList'
      }),
      beginTime() {
        const arr = this.time.time_b.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      },
      endTime() {
        const arr = this.time.time_e.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      }
    },

    watch: {
      getCurrentBranch() {
        this.currentBranch = []
      }
    },
    updated() {
      console.log(this.data_range)
    },

    created() {
      this.prepareDates()
    },

    methods: {
      ...mapMutations({
        SET_CURRENT_BRANCH_CLASSIF_LIST: 'settings/SET_CURRENT_BRANCH_CLASSIF_LIST'
      }),

      updateDateRange() {
        // this.$apollo.queries.OutgoingHeads.refetch()
      },

      setBranches(newVal) {
        if (newVal.length === 0) {
          this.SET_CURRENT_BRANCH_CLASSIF_LIST(this.getCurrentBranch?.branches)
        } else {
          this.SET_CURRENT_BRANCH_CLASSIF_LIST(newVal)
        }
      },

      convertDatetoTime(date) {
        if (date) return formatDate(date, 'date')
      },

      prepareDates() {
        const one_month_ago = new Date()
        const today = new Date()

        this.data_range = [new Date(one_month_ago.setMonth(one_month_ago.getMonth() - 1)), today]
      }
    }
  }
</script>

<style scoped lang="scss">
  .e-select-el {
    &__option-name {
      margin: 6px 0px 6px 0px;
      text-align: left;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
    &__option-description-block {
      display: flex;
      margin-bottom: 6px;
    }
    &__option-description {
      margin: 0px 5px 0px 0px;
      text-align: left;
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
  .select-block {
    width: 290px;
    margin-left: 10px;
  }

  // :deep .ms__dropdown {
  //   // padding-right: 5px;

  //   ul {
  //     overflow: hidden;
  //     padding-right: 10px !important;
  //   }
  // }

  :deep .branch_сlassif {
    height: 31px !important;
    border-radius: 4px !important;
    border-color: #dcdee2 !important;

    input {
      height: 28px !important;
    }
  }

  :deep .ivu-input {
    height: 31px !important;
    border-radius: 4px;
    border-color: #dcdee2 !important;
  }

  @media screen and (max-width: 1135px) {
    .block-cash-type {
      display: block !important;
    }
    .cash-type {
      margin: 0 !important;
    }
  }
  .block-cash-type {
    display: flex;
    flex-wrap: wrap;
    .cash-type {
      flex: 1;
      min-width: 500px;
      &:first-child {
        margin-right: 10px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
  #cash-sales-report {
    .b-filter-date {
      margin-top: 0;
      display: flex;
      align-items: center;

      .date {
        width: 208px;
        margin-right: 16px;
      }

      .time {
        width: 90px;
        margin: 0 4px;
      }
    }

    .b-total-period {
    }

    ::v-deep() {
      .chart-block {
        max-height: 100%;
        width: auto;

        .card {
          max-height: 100%;

          .card-body {
            max-height: 100%;
          }
        }
      }
    }

    .widget-height {
      margin-bottom: 16px;
    }

    .widget-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #313131;
    }
  }
</style>
