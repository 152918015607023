var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tables-container"
  }, [_c('b-tabs', {
    staticClass: "d-flex flex-column flex-grow-1 flex-shrink-1",
    attrs: {
      "active-nav-item-class": "font-weight-bold"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Чеки"
    }
  }, [_vm.tabIndex === 0 ? _c('receipts', {
    attrs: {
      "z_numbers_list": _vm.z_numbers_list,
      "data_range": _vm.data_range,
      "time": _vm.time
    }
  }) : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title": "Товары"
    }
  }), _vm.tabIndex === 1 ? _c('products-cash-sales', {
    attrs: {
      "z_numbers_list": _vm.z_numbers_list,
      "data_range": _vm.data_range,
      "time": _vm.time
    }
  }) : _vm._e(), _c('b-tab', {
    attrs: {
      "title": "Кассиры"
    }
  }, [_vm.tabIndex === 2 ? _c('cashier', {
    attrs: {
      "z_numbers_list": _vm.z_numbers_list,
      "data_range": _vm.data_range,
      "time": _vm.time
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }