<template>
  <div class="cashier_table">
    <resizable-table
      :busy="is_loading"
      table_name="cash_sales_cashier_table"
      :default_fields="fields"
      :items="items"
      @sort_change="(val) => $emit('sort_change', val)"
    >
      <template #body_user_name="{ item }">
        <span class="d-flex float-left">
          {{ item?.cash_name?.first_name || '-' }}
        </span>
      </template>
      <template #body_checks="{ item }">
        <span class="d-flex float-right">
          {{ item?.checks_q ? (+item?.checks_q.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_days="{ item }">
        <span class="d-flex float-right">
          {{ item?.days ?? '-' }}
        </span>
      </template>
      <template #body_cash="{ item }">
        <span class="d-flex float-right">
          {{ item?.revenue_cash ? (+item?.revenue_cash.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_card="{ item }">
        <span class="d-flex float-right">
          {{ item?.revenue_card ? (+item?.revenue_card.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_another="{ item }">
        <span class="d-flex float-right">
          {{ item?.revenue_other ? (+item?.revenue_other.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_discount="{ item }">
        <span class="d-flex float-right">
          {{ item?.discount ? (+item?.discount.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_discount_sum="{ item }">
        <span class="d-flex float-right">
          {{ item?.sum_discount ? (+item?.sum_discount.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_back="{ item }">
        <span class="d-flex float-right">
          {{ item?.back_q ? (+item?.back_q.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_back_sum="{ item }">
        <span class="d-flex float-right">
          {{ item?.sum_q ? (+item?.sum_q.toFixed(2)).toLocaleString() : 0 }}
        </span>
      </template>
      <template #total>
        <table-footer />
      </template>
    </resizable-table>
  </div>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import TableFooter from '@/views/analytics/components/CashSalesTabs/TableFooter'

  export default {
    name: 'CashierTable',
    components: {
      TableFooter,
      ResizableTable
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      is_loading: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        fields: [
          {
            key: 'user_name',
            label: 'Имя кассира',
            sortable: true,
            checked: true,
            width: 200
          },
          {
            key: 'days',
            label: 'Дней',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'checks',
            label: 'Чеки',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'cash',
            label: 'Выручка нал., ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'card',
            label: 'Выручка безнал., ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'another',
            label: 'Выручка иные оплаты, ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'back',
            label: 'Возвраты, ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'back_sum',
            label: 'Сумма возвратов, ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'discount',
            label: 'Скидки',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'discount_sum',
            label: 'Сумма скидок, ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep th.cursor {
    div {
      justify-content: flex-start !important;
    }
  }
  .cashier_table {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 400px;
  }
</style>
