var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "filters-block"
  }, [_c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.filters.selected_categories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('div', {
    staticClass: "filters"
  }, [!_vm.hidden_filters ? _c('div', {
    staticClass: "filters-content"
  }, [_c('div', {
    staticClass: "filter-item",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "categories-select",
    staticStyle: {
      "width": "205px"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.categoriesCount))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })])]), _c('e-select', {
    staticClass: "filters-item__cash",
    attrs: {
      "placeholder": "Все кассы",
      "key-name": "number",
      "multiple": "",
      "options": _vm.cashList
    },
    model: {
      value: _vm.filters.cashes,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "cashes", $$v);
      },
      expression: "filters.cashes"
    }
  }), _c('e-select', {
    staticClass: "filters-item__reports",
    attrs: {
      "options": _vm.z_numbers_list,
      "multiple": "",
      "placeholder": "Все Z-отчеты"
    },
    model: {
      value: _vm.filters.z_numbers,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "z_numbers", $$v);
      },
      expression: "filters.z_numbers"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "buttons"
  }, [_c('e-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('download');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      "src": "/img/icons/download.svg",
      "alt": "download"
    }
  }), _vm._v("Скачать ")]), _c('e-button', [_vm._v("Печать")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }