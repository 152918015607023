<template>
  <div class="container-cashier">
    <filters-cash-sales
      :hidden_filters="true"
      :z_numbers_list="z_numbers_list"
      @set_filters="setFilters"
      @download="downloadExel"
    />
    <cashier-table
      :items="items"
      :is_loading="is_loading"
      @sort_change="sort"
    />
    <pagination

      :total="total"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import FiltersCashSales from '@/views/analytics/components/FiltersCashSales'
  import CashierTable from '@/views/analytics/components/CashSalesTabs/CashierTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Cashier',
    components: { Pagination, CashierTable, FiltersCashSales },
    apollo: {
      CashList: {
        query: require('../../gql/CashList.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch?.__typename === 'BranchClassifModel' ? '' : this.currentBranch.id,
              period: {
                begin: new Date(new Date(this.data_range[0]).setHours(this.beginTime.hour, this.beginTime.minute, 0)),
                end: new Date(new Date(this.data_range[1]).setHours(this.endTime.hour, this.endTime.minute, 59))
              },
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take,
                order: this.pagination.order
              }
            },
            branches:
              this.getCurrentBranchClassifList?.length > 0 ? this.getCurrentBranchClassifList?.map((el) => el.id) : null
          }
        },
        result({ data }) {
          this.items = data?.CashList.list ?? []
          this.total = data?.CashList.total ?? []
          this.is_loading = false
        }
      }
    },
    props: {
      data_range: {
        type: [Object, String, Array],
        default: () => {}
      },
      time: {
        type: Object
      },
      z_numbers_list: {
        type: Array
      }
    },
    data() {
      return {
        pagination: {
          skip: 0,
          take: 20,
          order: []
        },
        total: 0,
        is_loading: true,
        items: []
      }
    },

    computed: {
      ...mapGetters({ 
        currentBranch: 'settings/getCurrentBranch',
        getCurrentBranchClassifList: 'settings/getCurrentBranchClassifList'
      }),
      beginTime() {
        const arr = this.time.time_b.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      },
      endTime() {
        const arr = this.time.time_e.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      }
    },

    methods: {
      async downloadExel() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/CashExel.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id,
              period: {
                begin: new Date(new Date(this.data_range[0]).setHours(this.beginTime.hour, this.beginTime.minute, 0)),
                end: new Date(new Date(this.data_range[1]).setHours(this.endTime.hour, this.endTime.minute, 59))
              },
              cash_id: this.filters?.cashes.map((el) => el.id),
              z_number: this.filters?.z_numbers.map((el) => +el.id),
              category_id: this.filters?.selected_categories
            }
          }
        })
        if (data?.CashExel) {
          const link = document.createElement('a')
          link.href = data?.CashExel?.url
          link.download = 'exel'
          link.click()
          URL.revokeObjectURL(link.href)
        } else {
          this.$noty.error('Ошибка при загруке файла')
        }
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      sort(val) {
        this.pagination.order = [
          {
            key: val.key,
            value: val.sort
          }
        ]
      },
      setFilters() {
        console.log('setFilters')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container-cashier {
    margin-top: 16px;
  }
</style>
