<template>
  <div id="payment-type">
    <div class="items">
      <div
        v-for="(item, index) in chart_data"
        :key="index"
        class="item"
      >
        <span class="color-circle"></span>
        {{ item.type }}, ₽
        <div class="price">{{ formatPrice(item.value) }}</div>
        <div class="percent">{{ calcPercent(item.value) }} %</div>
      </div>
    </div>
    <b-progress
      v-if="max"
      class="mt-4"
      :max="max"
    >
      <b-progress-bar
        v-for="(item, index) in chart_data"
        :key="index"
        :value="item.value"
      />
    </b-progress>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      period: {
        type: [Object, String, Array],
        default: () => {}
      },
      time: {
        type: Object
      }
    },

    apollo: {
      CheckTypeAnalytics: {
        query: require('../gql/CheckTypeAnalytics.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch?.__typename === 'BranchClassifModel' ? '' : this.currentBranch.id,
            period: {
              begin: new Date(new Date(this.period[0]).setHours(this.beginTime.hour, this.beginTime.minute, 0)),
              end: new Date(new Date(this.period[1]).setHours(this.endTime.hour, this.endTime.minute, 59))
            },
            branches:
              this.getCurrentBranchClassifList?.length > 0 ? this.getCurrentBranchClassifList?.map((el) => el.id) : null
          }
        },
        result({ data }) {
          this.chart_data = data?.CheckTypeAnalytics ?? []

          this.max = 0
          this.chart_data.forEach((item) => (this.max = this.max + item.value))
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getCurrentBranchClassifList: 'settings/getCurrentBranchClassifList'
      }),
      beginTime() {
        const arr = this.time.time_b.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      },
      endTime() {
        const arr = this.time.time_e.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      }
    },

    data: function () {
      return {
        chart_data: null,
        max: 0
      }
    },

    methods: {
      calcPercent(value) {
        const onePercent = this.max / 100
        if (onePercent === 0) return onePercent.toFixed(2)
        return (value / onePercent).toFixed(2)
      },

      formatPrice(val) {
        return val.toLocaleString('ru-RU', {
          style: 'currency',
          currency: 'RUB'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  #payment-type {
    .items {
      display: flex;
      justify-content: flex-start;

      .item {
        border-left: 1px solid #e1e1e1;
        padding: 2px 40px 2px 12px;
        margin-top: 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #888888;

        &:first-child {
          border-left: none;
        }

        span.color-circle {
          width: 12px;
          height: 12px;
          background: #aabde4;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;
        }

        .price {
          font-size: 18px;
          line-height: 21px;
          color: #313131;
          margin: 6px 0;
        }

        &:nth-child(2) {
          span.color-circle {
            background: #fae7cd;
          }
        }

        &:nth-child(3) {
          span.color-circle {
            background: #e5ace8;
          }
        }
      }
    }

    ::v-deep() {
      .progress {
        background: none;
        height: 19px;

        .progress-bar {
          height: 18px;
          background: #aabde4;
          margin: 0 1px 0 0;
          border-radius: 2px;

          &:nth-child(2) {
            background: #fae7cd;
          }

          &:nth-child(3) {
            background: #e5ace8;
          }
        }
      }
    }
  }
</style>
