<template>
  <div id="charts_sums">
    <b-tabs v-model="tabIndex">
      <b-tab
        title="Сумма по часам"
        active
      >
        <bar
          v-if="!loading && tabIndex === 0"
          :chart-options="chartOptions"
          :chart-data="chart_sum"
          :chart-id="chart_id"
          :height="chart_settings.height"
          css-classes="chart_sum"
        />
      </b-tab>
      <b-tab title="Количество по часам">
        <bar
          v-if="!loading && tabIndex === 1"
          :chart-options="chartOptions"
          :chart-data="chart_count"
          :chart-id="chart_id"
          :height="chart_settings.height"
          css-classes="chart_count"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import { Bar } from 'vue-chartjs/legacy'
  import { mapGetters } from 'vuex'
  import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  export default {
    components: { Bar },

    apollo: {
      CheckHoursAnalytics: {
        query: require('../gql/CheckHoursAnalytics.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch?.__typename === 'BranchClassifModel' ? '' : this.currentBranch.id,
            period: {
              begin: new Date(new Date(this.period?.[0]).setHours(this.beginTime.hour, this.beginTime.minute, 0)),
              end: new Date(new Date(this.period?.[1]).setHours(this.endTime.hour, this.endTime.minute, 59))
            },
            branches:
              this.getCurrentBranchClassifList?.length > 0 ? this.getCurrentBranchClassifList?.map((el) => el.id) : null
          }
        },
        result({ data }) {
          this.loading = true

          this.prepareData(data?.CheckHoursAnalytics ?? [])
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },

    props: {
      period: {
        type: [Object, String, Array],
        default: () => {}
      },
      chart_id: {
        type: String,
        default: 'column'
      },
      dataset_id_key: {
        type: String,
        default: 'label'
      },
      time: {
        type: Object
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getCurrentBranchClassifList: 'settings/getCurrentBranchClassifList'
      }),
      beginTime() {
        const arr = this.time.time_b.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      },
      endTime() {
        const arr = this.time.time_e.split(':')
        const hourBegin = arr[0]
        const minuteBegin = arr[1]
        return {
          hour: +hourBegin,
          minute: +minuteBegin
        }
      }
    },

    data() {
      return {
        loading: true,
        tabIndex: 0,
        chart_sum: {
          labels: [],
          datasets: [
            {
              backgroundColor: '#c5dbba',
              data: []
            }
          ]
        },
        chart_count: {
          labels: [],
          datasets: [
            {
              backgroundColor: '#c5dbba',
              data: []
            }
          ]
        },
        chart_settings: {
          height: 210
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          }
        }
      }
    },

    methods: {
      prepareData(items) {
        this.chart_sum.labels = []
        this.chart_sum.datasets[0].data = []

        this.chart_count.labels = []
        this.chart_count.datasets[0].data = []
        items.forEach((item) => {
          this.chart_sum.labels.push(item.label)
          this.chart_sum.datasets[0].data.push(item.sum)

          this.chart_count.labels.push(item.label)
          this.chart_count.datasets[0].data.push(item.quantity)
        })

        this.loading = false
      }
    }
  }
</script>

<style scoped lang="scss">
  #charts_sums {
    height: 234px;

    ::v-deep() {
      .tabs {
        .nav-tabs {
          border-bottom: none;

          li {
            width: auto;

            .nav-link {
              padding: 0 25px 5px;
            }
          }
        }

        .tab-content {
          padding-top: 10px;
        }
      }

      .card {
        padding: 0;
      }
    }
  }
</style>
