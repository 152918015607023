<template>
  <div class="products_cash_table">
    <resizable-table
      :busy="is_loading"
      table_name="cash_sales_list_products"
      :default_fields="fields"
      :items="items"
      @sort_change="(val) => $emit('sort_change', val)"
    >
      <template #body_product_name="{ item }">
        <span class="d-flex float-left">
          {{ item?.product_name?.name }}
        </span>
      </template>
      <template #body_product_group="{ item }">
        <span class="d-flex float-left">
          {{ item?.product_name?.category?.name }}
        </span>
      </template>
      <template #body_sale_q="{ item }">
        <span class="d-flex float-right">
          {{ (+item?.sale_q?.toFixed(2))?.toLocaleString() }}
        </span>
      </template>
      <template #body_sale_sum="{ item }">
        <span class="d-flex float-right">
          {{ (+item?.sum?.toFixed(2))?.toLocaleString() }}
        </span>
      </template>
      <template #body_back_q="{ item }">
        <span class="d-flex float-right">
          {{ item?.back_q ?? 0 }}
        </span>
      </template>
      <template #body_back_sum="{ item }">
        <span class="d-flex float-right">
          {{ item?.sum_q ? (+item?.sum_q?.toFixed(2))?.toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_remainder="{ item }">
        <span class="d-flex float-right">
          {{
            item?.product_name?.remainders
              ? item.product_name.remainders.reduce((acc, { value }) => (acc += value), 0)
              : 0
          }}
        </span>
      </template>
      <template #body_diff="{ item }">
        <span class="d-flex float-right">
          {{ item.diff ? (+item.diff?.toFixed(2))?.toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_sum_diff="{ item }">
        <span class="d-flex float-right">
          {{ item?.sum_diff ? (+item?.sum_diff?.toFixed(2))?.toLocaleString() : 0 }}
        </span>
      </template>
      <template #total>
        <table-footer />
      </template>
    </resizable-table>
  </div>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import TableFooter from '@/views/analytics/components/CashSalesTabs/TableFooter'

  export default {
    name: 'ProductsTableCash',
    components: {
      TableFooter,
      ResizableTable
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      is_loading: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        fields: [
          {
            key: 'product_name',
            label: 'Наименование товара',
            sortable: true,
            checked: true,
            width: 200
          },
          {
            key: 'product_group',
            label: 'Товарная группа',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'sale_q',
            label: 'Кол-во продаж',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'sale_sum',
            label: 'Сумма продаж',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'back_q',
            label: 'Кол-во возвратов',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'back_sum',
            label: 'Сумма возвратов',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'remainder',
            label: 'Остаток',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'diff',
            label: 'Разница по кол-ву, шт ',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'sum_diff',
            label: 'Разница по сумме, ₽',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep th.cursor {
    div {
      justify-content: flex-start !important;
    }
  }
  .products_cash_table {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 400px;
  }
</style>
