var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "payment-type"
    }
  }, [_c('div', {
    staticClass: "items"
  }, _vm._l(_vm.chart_data, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('span', {
      staticClass: "color-circle"
    }), _vm._v(" " + _vm._s(item.type) + ", ₽ "), _c('div', {
      staticClass: "price"
    }, [_vm._v(_vm._s(_vm.formatPrice(item.value)))]), _c('div', {
      staticClass: "percent"
    }, [_vm._v(_vm._s(_vm.calcPercent(item.value)) + " %")])]);
  }), 0), _vm.max ? _c('b-progress', {
    staticClass: "mt-4",
    attrs: {
      "max": _vm.max
    }
  }, _vm._l(_vm.chart_data, function (item, index) {
    return _c('b-progress-bar', {
      key: index,
      attrs: {
        "value": item.value
      }
    });
  }), 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }