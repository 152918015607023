var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cashier_table"
  }, [_c('resizable-table', {
    attrs: {
      "busy": _vm.is_loading,
      "table_name": "cash_sales_cashier_table",
      "default_fields": _vm.fields,
      "items": _vm.items
    },
    on: {
      "sort_change": function (val) {
        return _vm.$emit('sort_change', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "body_user_name",
      fn: function (_ref) {
        var _item$cash_name;

        var item = _ref.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$cash_name = item.cash_name) === null || _item$cash_name === void 0 ? void 0 : _item$cash_name.first_name) || '-') + " ")])];
      }
    }, {
      key: "body_checks",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.checks_q ? (+(item === null || item === void 0 ? void 0 : item.checks_q.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_days",
      fn: function (_ref3) {
        var _item$days;

        var item = _ref3.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_item$days = item === null || item === void 0 ? void 0 : item.days) !== null && _item$days !== void 0 ? _item$days : '-') + " ")])];
      }
    }, {
      key: "body_cash",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.revenue_cash ? (+(item === null || item === void 0 ? void 0 : item.revenue_cash.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_card",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.revenue_card ? (+(item === null || item === void 0 ? void 0 : item.revenue_card.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_another",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.revenue_other ? (+(item === null || item === void 0 ? void 0 : item.revenue_other.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_discount",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.discount ? (+(item === null || item === void 0 ? void 0 : item.discount.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_discount_sum",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.sum_discount ? (+(item === null || item === void 0 ? void 0 : item.sum_discount.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_back",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.back_q ? (+(item === null || item === void 0 ? void 0 : item.back_q.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_back_sum",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.sum_q ? (+(item === null || item === void 0 ? void 0 : item.sum_q.toFixed(2))).toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "total",
      fn: function () {
        return [_c('table-footer')];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }