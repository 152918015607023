var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "receipts_table"
  }, [_c('resizable-table', {
    attrs: {
      "busy": _vm.is_loading,
      "table_name": "cash_sales_check",
      "default_fields": _vm.fields,
      "items": _vm.items
    },
    on: {
      "sort_change": function (val) {
        return _vm.$emit('sort_change', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "body_cash_n",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(_vm._s(item.cash.number))])];
      }
    }, {
      key: "body_z_number",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.z_num) + " ")])];
      }
    }, {
      key: "body_pay_type",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.getPayType(value)) + " ")])];
      }
    }, {
      key: "body_sum",
      fn: function (_ref4) {
        var _item$sum, _item$sum$toFixed;

        var item = _ref4.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.sum ? +((_item$sum = item.sum) === null || _item$sum === void 0 ? void 0 : (_item$sum$toFixed = _item$sum.toFixed(2)) === null || _item$sum$toFixed === void 0 ? void 0 : _item$sum$toFixed.toLocaleString()) : 0) + " ")])];
      }
    }, {
      key: "body_back_sum",
      fn: function (_ref5) {
        var _item$back_sum, _item$back_sum$toFixe;

        var item = _ref5.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.back_sum ? +((_item$back_sum = item.back_sum) === null || _item$back_sum === void 0 ? void 0 : (_item$back_sum$toFixe = _item$back_sum.toFixed(2)) === null || _item$back_sum$toFixe === void 0 ? void 0 : _item$back_sum$toFixe.toLocaleString()) : 0) + " ")])];
      }
    }, {
      key: "body_ch_num",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.check_num) + " ")])];
      }
    }, {
      key: "body_pos_num",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.pos_q) + " ")])];
      }
    }, {
      key: "body_date",
      fn: function (_ref8) {
        var value = _ref8.value;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.getDate(value)) + " ")])];
      }
    }, {
      key: "total",
      fn: function () {
        return [_c('table-footer')];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }