<template>
  <div class="filters-block">
    <categories-load-modal
      :included_categories="filters.selected_categories"
      @add-categories="addCategories"
    />
    <div class="filters">
      <div
        v-if="!hidden_filters"
        class="filters-content"
      >
        <div
          class="filter-item"
          @click.capture="showLoadModal"
        >
          <div
            class="categories-select"
            style="width: 205px"
          >
            <span style="color: black; white-space: nowrap"> {{ categoriesCount }}</span>
            <img
              src="/img/icons/arrow_up.svg"
              style="transform: rotate(180deg)"
            />
          </div>
        </div>
        <e-select
          v-model="filters.cashes"
          class="filters-item__cash"
          placeholder="Все кассы"
          key-name="number"
          multiple
          :options="cashList"
        />
        <e-select
          v-model="filters.z_numbers"
          class="filters-item__reports"
          :options="z_numbers_list"
          multiple
          placeholder="Все Z-отчеты"
        />
        <!--      <e-select-->
        <!--        class="filters-item__receipts"-->
        <!--        placeholder="Все чеки"-->
        <!--      />-->
      </div>
    </div>
    <div class="buttons">
      <e-button @click="$emit('download')">
        <img
          style="margin-right: 12px"
          src="/img/icons/download.svg"
          alt="download"
        />Скачать
      </e-button>
      <e-button>Печать</e-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'

  export default {
    name: 'FiltersCashSales',
    components: { CategoriesLoadModal },
    props: {
      z_numbers_list: {
        type: Array
      },
      hidden_filters: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filters: {
          selected_categories: [],
          z_numbers: [],
          cashes: []
        }
      }
    },
    computed: {
      ...mapGetters({ getCurrentBranch: 'settings/getCurrentBranch', getBranchList: 'settings/getBranchList' }),
      cashList() {
        const arrCash = this.getBranchList
          ?.find((obj) => obj?.id === this?.getCurrentBranch?.id)
          ?.cash?.map((obj) => obj)
        console.log(arrCash)
        return arrCash ?? []
      },
      categoriesCount() {
        if (this.filters.selected_categories?.length) {
          return 'Выбрано ' + this.filters.selected_categories?.length
        }
        return 'Все группы товаров'
      }
    },
    watch: {
      'filters.cashes'() {
        this.$emit('set_filters', this.filters)
      },
      'filters.z_numbers'() {
        this.$emit('set_filters', this.filters)
      },
      'filters.selected_categories'() {
        this.$emit('set_filters', this.filters)
      }
    },
    methods: {
      async addCategories(cats) {
        this.filters.selected_categories = cats
      },
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter-item {
    width: 205px !important;
    min-width: initial !important;
    max-width: initial !important;
    margin: 0 16px 0 0 !important;
  }

  .filters-content {
    display: flex;
    align-items: center;
  }

  .categories-select {
    display: flex;
    flex-direction: row;
    background: white;
    border: 1px solid #bcb8b8;
    border-radius: 2px;
    height: 33px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    justify-content: space-between;

    span {
      color: #bcbcbc !important;
    }
  }

  .filters-block {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      flex: 1;
      align-items: center;

      &-item {
        &__product {
          margin-right: 16px;
          min-width: 200px;
          max-width: 200px;
        }

        &__cash {
          margin-right: 16px;
          min-width: 140px;
          max-width: 140px;
        }

        &__reports {
          margin-right: 16px;
          max-width: 150px;
          min-width: 150px;
        }

        &__receipts {
          margin-right: 16px;
          max-width: 150px;
          min-width: 150px;
        }

        &__date {
          margin-right: 16px;
          max-width: 150px;
          min-width: 150px;
        }
      }
    }

    .buttons {
      display: flex;

      button {
        align-items: center;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
