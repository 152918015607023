var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "analytics"
    }
  }, [_c('b-row', {
    staticClass: "mt-4",
    staticStyle: {
      "row-gap": "1.5rem"
    }
  }, [_c('b-col', {
    attrs: {
      "xl": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "analytics__item"
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v("Выручка, ₽")]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.analytics.revenue)) + " ")])])]), _c('b-col', {
    attrs: {
      "xl": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "analytics__item"
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v("Возвраты, ₽")]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.analytics.returns)) + " ")])])]), _c('b-col', {
    attrs: {
      "xl": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "analytics__item"
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v("Всего чеков, шт")]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.analytics.quantity) + " ")])])]), _c('b-col', {
    attrs: {
      "xl": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "analytics__item"
  }, [_c('div', {
    staticClass: "analytics__item-title"
  }, [_vm._v("Средний чек, ₽")]), _c('div', {
    staticClass: "analytics__item-sum"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.analytics.average)) + " ")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }