var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "products_cash_table"
  }, [_c('resizable-table', {
    attrs: {
      "busy": _vm.is_loading,
      "table_name": "cash_sales_list_products",
      "default_fields": _vm.fields,
      "items": _vm.items
    },
    on: {
      "sort_change": function (val) {
        return _vm.$emit('sort_change', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "body_product_name",
      fn: function (_ref) {
        var _item$product_name;

        var item = _ref.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$product_name = item.product_name) === null || _item$product_name === void 0 ? void 0 : _item$product_name.name) + " ")])];
      }
    }, {
      key: "body_product_group",
      fn: function (_ref2) {
        var _item$product_name2, _item$product_name2$c;

        var item = _ref2.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$product_name2 = item.product_name) === null || _item$product_name2 === void 0 ? void 0 : (_item$product_name2$c = _item$product_name2.category) === null || _item$product_name2$c === void 0 ? void 0 : _item$product_name2$c.name) + " ")])];
      }
    }, {
      key: "body_sale_q",
      fn: function (_ref3) {
        var _item$sale_q$toFixed, _item$sale_q;

        var item = _ref3.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_item$sale_q$toFixed = +(item === null || item === void 0 ? void 0 : (_item$sale_q = item.sale_q) === null || _item$sale_q === void 0 ? void 0 : _item$sale_q.toFixed(2))) === null || _item$sale_q$toFixed === void 0 ? void 0 : _item$sale_q$toFixed.toLocaleString()) + " ")])];
      }
    }, {
      key: "body_sale_sum",
      fn: function (_ref4) {
        var _item$sum$toFixed, _item$sum;

        var item = _ref4.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_item$sum$toFixed = +(item === null || item === void 0 ? void 0 : (_item$sum = item.sum) === null || _item$sum === void 0 ? void 0 : _item$sum.toFixed(2))) === null || _item$sum$toFixed === void 0 ? void 0 : _item$sum$toFixed.toLocaleString()) + " ")])];
      }
    }, {
      key: "body_back_q",
      fn: function (_ref5) {
        var _item$back_q;

        var item = _ref5.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_item$back_q = item === null || item === void 0 ? void 0 : item.back_q) !== null && _item$back_q !== void 0 ? _item$back_q : 0) + " ")])];
      }
    }, {
      key: "body_back_sum",
      fn: function (_ref6) {
        var _item$sum_q$toFixed, _item$sum_q;

        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.sum_q ? (_item$sum_q$toFixed = +(item === null || item === void 0 ? void 0 : (_item$sum_q = item.sum_q) === null || _item$sum_q === void 0 ? void 0 : _item$sum_q.toFixed(2))) === null || _item$sum_q$toFixed === void 0 ? void 0 : _item$sum_q$toFixed.toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_remainder",
      fn: function (_ref7) {
        var _item$product_name3;

        var item = _ref7.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && (_item$product_name3 = item.product_name) !== null && _item$product_name3 !== void 0 && _item$product_name3.remainders ? item.product_name.remainders.reduce(function (acc, _ref8) {
          var value = _ref8.value;
          return acc += value;
        }, 0) : 0) + " ")])];
      }
    }, {
      key: "body_diff",
      fn: function (_ref9) {
        var _item$diff$toFixed, _item$diff;

        var item = _ref9.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.diff ? (_item$diff$toFixed = +((_item$diff = item.diff) === null || _item$diff === void 0 ? void 0 : _item$diff.toFixed(2))) === null || _item$diff$toFixed === void 0 ? void 0 : _item$diff$toFixed.toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "body_sum_diff",
      fn: function (_ref10) {
        var _item$sum_diff$toFixe, _item$sum_diff;

        var item = _ref10.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.sum_diff ? (_item$sum_diff$toFixe = +(item === null || item === void 0 ? void 0 : (_item$sum_diff = item.sum_diff) === null || _item$sum_diff === void 0 ? void 0 : _item$sum_diff.toFixed(2))) === null || _item$sum_diff$toFixe === void 0 ? void 0 : _item$sum_diff$toFixe.toLocaleString() : 0) + " ")])];
      }
    }, {
      key: "total",
      fn: function () {
        return [_c('table-footer')];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }