var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-cashier"
  }, [_c('filters-cash-sales', {
    attrs: {
      "hidden_filters": true,
      "z_numbers_list": _vm.z_numbers_list
    },
    on: {
      "set_filters": _vm.setFilters,
      "download": _vm.downloadExel
    }
  }), _c('cashier-table', {
    attrs: {
      "items": _vm.items,
      "is_loading": _vm.is_loading
    },
    on: {
      "sort_change": _vm.sort
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.total
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }