var render = function render(){
  var _vm$getCurrentBranch, _vm$getCurrentBranch2, _vm$getCurrentBranch3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "cash-sales-report"
    }
  }, [_c('div', {
    staticClass: "b-filter-date"
  }, [_c('date-picker', {
    staticClass: "elm-calendar date",
    attrs: {
      "type": "datetimerange",
      "format": "dd.MM.yyyy",
      "clearable": false,
      "placeholder": "Выберите период"
    },
    on: {
      "on-change": _vm.updateDateRange
    },
    model: {
      value: _vm.data_range,
      callback: function ($$v) {
        _vm.data_range = $$v;
      },
      expression: "data_range"
    }
  }), _c('time-picker', {
    staticClass: "time",
    attrs: {
      "theme": "white",
      "value": _vm.time.time_b,
      "locale": "ru",
      "is24hr": "",
      "clearable": false,
      "format": "HH:mm"
    },
    model: {
      value: _vm.time.time_b,
      callback: function ($$v) {
        _vm.$set(_vm.time, "time_b", $$v);
      },
      expression: "time.time_b"
    }
  }), _c('b', [_vm._v("-")]), _c('time-picker', {
    staticClass: "time",
    attrs: {
      "theme": "white",
      "value": _vm.time.time_e,
      "locale": "ru",
      "is24hr": "",
      "clearable": false,
      "format": "HH:mm"
    },
    model: {
      value: _vm.time.time_e,
      callback: function ($$v) {
        _vm.$set(_vm.time, "time_e", $$v);
      },
      expression: "time.time_e"
    }
  }), (_vm$getCurrentBranch = _vm.getCurrentBranch) !== null && _vm$getCurrentBranch !== void 0 && _vm$getCurrentBranch.branches ? _c('div', {
    staticClass: "select-block"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.currentBranch,
      "options": (_vm$getCurrentBranch2 = _vm.getCurrentBranch) === null || _vm$getCurrentBranch2 === void 0 ? void 0 : _vm$getCurrentBranch2.branches,
      "multiple": "",
      "max_selected_count": 1,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": (_vm$getCurrentBranch3 = _vm.getCurrentBranch) === null || _vm$getCurrentBranch3 === void 0 ? void 0 : _vm$getCurrentBranch3.name,
      "select_name": "branch_сlassif"
    },
    on: {
      "input": _vm.setBranches
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }], null, false, 1931744915)
  })], 1) : _vm._e()], 1), _c('b-row', {
    staticClass: "widget-height mt-3",
    staticStyle: {
      "row-gap": "1.5rem"
    }
  }, [_c('b-col', {
    attrs: {
      "xl": "4",
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-0 mb-0 h-100"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Всего за период")]), _c('total-period', {
    attrs: {
      "period": _vm.data_range,
      "time": _vm.time
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "8",
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-0 m-0 h-100"
  }, [_c('data-by-hours', {
    attrs: {
      "period": _vm.data_range,
      "time": _vm.time
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "block-cash-type"
  }, [_c('div', {
    staticClass: "cash-type"
  }, [_c('b-card', {
    staticClass: "p-0"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Тип оплаты по продажам")]), _c('payment-type', {
    attrs: {
      "period": _vm.data_range,
      "time": _vm.time
    }
  })], 1)], 1), _c('div', {
    staticClass: "cash-type"
  }, [_c('b-card', {
    staticClass: "p-0"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v("Тип оплаты по возвратам")]), _c('return-type', {
    attrs: {
      "period": _vm.data_range,
      "time": _vm.time
    }
  })], 1)], 1)]), _c('cash-tabs', {
    attrs: {
      "data_range": _vm.data_range,
      "z_numbers_list": _vm.z_numbers_list,
      "time": _vm.time
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }