<template>
  <div class="tables-container">
    <b-tabs
      v-model="tabIndex"
      class="d-flex flex-column flex-grow-1 flex-shrink-1"
      active-nav-item-class="font-weight-bold"
    >
      <b-tab title="Чеки">
        <receipts
          v-if="tabIndex === 0"
          :z_numbers_list="z_numbers_list"
          :data_range="data_range"
          :time="time"
        />
      </b-tab>
      <b-tab title="Товары" />
      <products-cash-sales
        v-if="tabIndex === 1"
        :z_numbers_list="z_numbers_list"
        :data_range="data_range"
        :time="time"
      />
      <b-tab title="Кассиры">
        <cashier
          v-if="tabIndex === 2"
          :z_numbers_list="z_numbers_list"
          :data_range="data_range"
          :time="time"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import Cashier from '@/views/analytics/components/CashSalesTabs/Cashier'
  import ProductsCashSales from '@/views/analytics/components/CashSalesTabs/ProductsCashSales'
  import Receipts from '@/views/analytics/components/CashSalesTabs/Receipts'

  export default {
    name: 'CashTabs',
    components: { Receipts, ProductsCashSales, Cashier },
    props: {
      data_range: {
        type: [Object, String, Array],
        default: () => {}
      },
      time: {
        type: Object
      },
      z_numbers_list: {
        type: Array
      }
    },
    data() {
      return {
        tabIndex: 0
      }
    }
  }
</script>

<style lang="scss" scoped></style>
