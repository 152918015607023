<template>
  <div class="receipts_table">
    <resizable-table
      :busy="is_loading"
      table_name="cash_sales_check"
      :default_fields="fields"
      :items="items"
      @sort_change="(val) => $emit('sort_change', val)"
    >
      <template #body_cash_n="{ item }">
        <span class="d-flex float-right">{{ item.cash.number }}</span>
      </template>
      <template #body_z_number="{ item }">
        <span class="d-flex float-right">
          {{ item.z_num }}
        </span>
      </template>
      <template #body_pay_type="{ value }">
        <span class="d-flex float-left">
          {{ getPayType(value) }}
        </span>
      </template>
      <template #body_sum="{ item }">
        <span class="d-flex float-right">
          {{ item.sum ? +item.sum?.toFixed(2)?.toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_back_sum="{ item }">
        <span class="d-flex float-right">
          {{ item.back_sum ? +item.back_sum?.toFixed(2)?.toLocaleString() : 0 }}
        </span>
      </template>
      <template #body_ch_num="{ item }">
        <span class="d-flex float-right">
          {{ item.check_num }}
        </span>
      </template>
      <template #body_pos_num="{ item }">
        <span class="d-flex float-right">
          {{ item.pos_q }}
        </span>
      </template>
      <template #body_date="{ value }">
        <span class="d-flex float-left">
          {{ getDate(value) }}
        </span>
      </template>
      <template #total>
        <table-footer />
      </template>
    </resizable-table>
  </div>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import TableFooter from '@/views/analytics/components/CashSalesTabs/TableFooter'

  export default {
    name: 'TableReceipts',
    components: {
      TableFooter,
      ResizableTable
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      is_loading: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        fields: [
          {
            key: 'cash_n',
            label: '№ Кассы',
            sortable: true,
            checked: true,
            width: 200
          },
          {
            key: 'z_number',
            label: '№ Z-отчета',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'sum',
            label: 'Сумма продажи',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'back_sum',
            label: 'Сумма возвратов',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'ch_num',
            label: 'Номер чека',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'pos_num',
            label: 'Кол-во позиций',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'pay_type',
            label: 'Тип оплаты',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          },
          {
            key: 'date',
            label: 'Дата продажи',
            class: '',
            checked: true,
            sortable: true,
            width: 200
          }
        ]
      }
    },
    methods: {
      getDate(date) {
        return new Date(date).toLocaleDateString()
      },
      getPayType(val) {
        return val === 'CASH' ? 'Наличными' : 'Безналичный'
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep th.cursor {
    div {
      justify-content: flex-start !important;
    }
  }
  .receipts_table {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 400px;
  }
</style>
